<template>
    <div class="main-container">
      <div class="main-heading">
        <h1>Coop Spawner</h1>
      </div>
      <div class="container">
          <div v-for="(arena, index) in isSectionOpen" v-bind:key="index">
              <div class="arena-item" @click="() => toggleSection(index)">
                  <p> Wave {{ index*10 + 1 }}-{{ 10 * (index+1) }}</p>
              </div>
              <div class="arena-content" v-if="isSectionOpen[index]">
                  <div v-for="(pool, poolIndex) in coopObj.coop_data.slice(index*10, 10 * (index+1))" v-bind:key="poolIndex">
                    <div class="col">
                        <h4 style="text-align: start;">{{ "Wave " + (index*10 + poolIndex + 1)}}</h4>
                    </div>
                    <div class="row row-header">
                        <div>
                        Monster Count
                        </div>
                        <div>
                        Fast Monster Count
                        </div>
                        <div>
                        Miniboss Count
                        </div>
                        <div>
                        Boss Count
                        </div>
                    </div>
                    <div class="row">
                        <input v-model.number="coopObj.coop_data[(index*10 + poolIndex)].monster_count"/>
                        <input v-model.number="coopObj.coop_data[(index*10 + poolIndex)].fastmonster_count"/>
                        <input v-model.number="coopObj.coop_data[(index*10 + poolIndex)].miniboss_count"/>
                        <input v-model.number="coopObj.coop_data[(index*10 + poolIndex)].boss_count"/>
                    </div>
                    <div class="row row-header">
                        <div>
                        Monster Health
                        </div>
                        <div>
                        Fast Monster Health
                        </div>
                        <div>
                        Miniboss Health
                        </div>
                        <div>
                        Boss Health
                        </div>
                    </div>
                    <div class="row">
                        <input v-model.number="coopObj.coop_data[(index*10 + poolIndex)].monster_hp"/>
                        <input v-model.number="coopObj.coop_data[(index*10 + poolIndex)].fastmonster_hp"/>
                        <input v-model.number="coopObj.coop_data[(index*10 + poolIndex)].miniboss_hp"/>
                        <input v-model.number="coopObj.coop_data[(index*10 + poolIndex)].boss_hp"/>
                    </div>
                    <div class="row row-header">
                        <div>
                            Monster Laptime
                        </div>
                        <div>
                            Fast Monster Laptime
                        </div>
                        <div>
                            Miniboss Laptime
                        </div>
                        <div>
                            Boss Laptime
                        </div>
                    </div>
                    <div class="row">
                        <input v-model.number="coopObj.coop_data[(index*10 + poolIndex)].monster_laptime"/>
                        <input v-model.number="coopObj.coop_data[(index*10 + poolIndex)].fastmonster_laptime"/>
                        <input v-model.number="coopObj.coop_data[(index*10 + poolIndex)].miniboss_laptime"/>
                        <input v-model.number="coopObj.coop_data[(index*10 + poolIndex)].boss_laptime"/>
                     </div>
                    <div class="row row-header">
                        <div>
                            Key Count
                        </div>
                    </div>
                    <div class="row">
                        <input v-model.number="coopObj.coop_data[(index*10 + poolIndex)].key_count"/>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="buttons-section">
      <button class="success" @click="openConfirm">Save</button>
    </div>
          <ConfirmPopup :popup-open="popupOpen" :text="popupText" @popup-close="popupOpen=false" :type="popupType" :changes="changes" @confirm="submit"/>
    </div>
  </template>
  
  <script>
  import { watch, ref, computed, onBeforeMount,  onBeforeUnmount, onUpdated } from "vue";
  import { useStore } from "vuex";
  import shopConstants from "../../constants/shopConstants"
  import dispatchMap from "@/constants/dispatchMap";
  import { io } from "socket.io-client";
  import ConfirmPopup from '../../components/common/ConfirmPopup.vue'
  //import _ from "lodash";
  
  export default {
    name: "CoopSpawners",
    components: {
      ConfirmPopup
    },
    setup() {
      const store = useStore();
      const coopObj = ref([])
      const isSectionOpen = ref([])
      const usedPages = ["spawner"];
      const socket = io(process.env.VUE_APP_BACKEND_URL);
      const popupOpen = ref(false);
      const popupTexts = ["This page is edited by another user. Please refresh before making changes.", "Your changes: "];
      const popupText = ref(popupTexts[0])
      const popupType = ref("refresh");
      const changes = ref([]);
  
      onBeforeMount(() => usedPages.forEach(page => {
        dispatchMap[page].forEach(dispatchStr => store.dispatch(dispatchStr));
      }));
      watch(() => store.getters['spawners/getSpawner'], value => {            
          if (value) {
              coopObj.value = JSON.parse(JSON.stringify(value))
              console.log(coopObj.value)
              isSectionOpen.value = new Array(coopObj.value.coop_data.length/10).fill(false)
          }
      }, { immediate: true })
  
      const submit = () => {
            console.log(coopObj.value.coop_data)
            store.dispatch('spawners/updateSpawner', coopObj.value).then(() => {
                socket.emit("update", {
                socketId: socket.id,
                page: "Coop Spawner",
                data: coopObj.value
                });
                popupOpen.value = false;
                changes.value = [];
            })
        }
      onUpdated(() => store.dispatch("loader/loadingStatus", false));
      onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
      
      /*const exportTo = environment => {
          store.dispatch('shop/exportShopContent', { shopContent: coopObj.value.content, environment })
      }*/
  
      const toggleSection = (sectionIndex) => {
          isSectionOpen.value[sectionIndex] = !isSectionOpen.value[sectionIndex]
      }
  
      const initializeRewardType = (reward) => {
          if ([0,1,4].includes(reward.type)) {
              if (reward.rarity !== undefined)
                  delete reward.rarity;
          } else {
              if (!reward.rarity)
                  reward.rarity = 0
              if(reward.chest_id)
                delete reward.chest_id;
          }
      }
  
      const onCurrencyChange = (currency, priceObject) => {
          priceObject[currency] = 0
          delete priceObject[currency == 'gold' ? 'gem' : 'gold']
      }
  
      
      socket.on('updated', (data) => {
        if (data.socketId != socket.id && data.page === "Coop Spawner" && data.data){
          popupOpen.value = true;
          popupType.value = "refresh";
          popupText.value = popupTexts[0];
        }
      })
  
      const openConfirm = () => {
        popupType.value = "confirm";
        popupOpen.value = true;
        popupText.value = popupTexts[1];
      }
  
      const appConfig = computed(() => store.getters['auth/getAppConfig'])
      const env = appConfig.value.appEnv || 'dev'
  
      return {
        coopObj,
        //submit,
        //exportTo,
        env,
        isSectionOpen,
        toggleSection,
        shopConstants,
        initializeRewardType,
        onCurrencyChange,
        //addPool,
        //removePool,
        popupOpen,
        popupText,
        popupType,
        changes,
        openConfirm,
        submit
      }
    }
  }
  </script>
  
  <style scoped>
  .main-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 30px;
  }
  
  .main-heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .col {
    padding: 15px;
  }
  
  .col-header {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .content-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
  }
  .content-row {
    padding: 15px;
    width: calc(100% - 30px);
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 2fr;
    grid-column-gap: 10px;
    align-items: center;
  }
  
  .content-row.header-row {
    font-weight: bold;
  }
  
  .content-row.alternate {
    background-color: lightgray;
  }
  
  .row-title {
    width: 80%;
    text-align: start;
  }
  
  button {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    border: 1px solid gray;
    padding: 5px 20px;
    margin: 5px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  
  button.error {
    background-color: rgba(255, 0, 0, 0.15);
  }
  
  button.success {
    background-color: rgba(0, 255, 0, 0.15);
  }
  
  button.info {
    background-color: rgba(0, 0, 255, 0.15);
  }
  
  .buttons-section {
    display: flex;
    padding: 30px 0;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
  }
  
  .buttons-section button {
    font-size: 1.25rem;
    margin: 5px 0;
    color: black
  }
  
  .col-full-width {
    width: 100%;
    box-sizing: border-box;
  }
  .table {
    width: 100%;
  }
  
  .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
  }
  
  .arena-item {
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px;
      border: 2px solid gray;
      border-radius: 10px;
      height: 50px;
      width: 80%;
      font-size: 20px;
      cursor: pointer;
  }
  
  .arena-content {
      margin: 20px;
  }
  
  .arena-item:hover {
      background-color: lavender;
  }
  
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    margin: 10px 0;
  }
  
  .row.header-row {
    background-color: #cccccc;
    font-weight: bold;
  }
  
  </style>