export default {
    REWARD_TYPES: [
        {
            text: 'GOLD',
            value: 0
        },
        {
            text: 'GEM',
            value: 1
        },
        {
            text: 'UNIT',
            value: 2
        },
        {
            text: 'HERO',
            value: 3
        },
        {
            text: 'CHEST',
            value: 4
        }
    ],
    CURRENCY_TYPES: [
        {
            text: "Gold",
            value: "gold"
        },
        {
            text: "Gem",
            value: "gem"
        }
    ],
    CARD_RARITY: [
        {
            text: 'COMMON',
            value: 0
        },
        {
            text: 'RARE',
            value: 1
        },
        {
            text: 'EPIC',
            value: 2
        },
        {
            text: 'LEGENDARY',
            value: 3
        }
    ],
    NEW_POOL: [
        { reward: { type: 0, amount: 100 }, price: { gold: 0} },
        { reward: { type: 0, amount: 100 }, price: { gold: 50} },
        { reward: { type: 0, amount: 100 }, price: { gold: 50} },
        { reward: { type: 2, rarity: 2, amount: 100 }, price: { gold: 50} },
        { reward: { type: 2, rarity: 2, amount: 100 }, price: { gold: 50} },
        { reward: { type: 2, rarity: 2, amount: 100 }, price: { gold: 50} }
    ]
}